import React from "react";
import "./ContactUs.css";
import whatsappImage from "../../../images/ContactUs/whatsapp.webp";

const ContactUs = () => {
  const number = "9351742998";
  const message = "Hello";
  return (
    <>
      <div className="contact-absolute">
        <a
          href={`https://wa.me/${number}?text=${message}`}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-btn"
        >
          <div className="d-flex align-items-center contact-text-wrapper">
            <div className="contactus-image-wrapper">
              <img src={whatsappImage} alt="whatsapp" />
            </div>
            <div className="px-2 contact-whatsapp-text">WhatsApp Us</div>
          </div>
        </a>
      </div>
    </>
  );
};

export default ContactUs;
