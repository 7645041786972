import React from "react";

const Testimonial = () => {
  return (
    <div style={{ height: "100vh", width: "100vw", overflow: "hidden" , position:"relative" , zIndex:"101" }}>
      <iframe
        id="testimonialto-testimonial"
        src="https://testimonial.to/lakebrains-technologies/all"
        frameBorder="0"
        width="100%"
        height="100%"
        style={{ display: "block", height: "100vh", width: "100vw" }}
      ></iframe>
    </div>
  );
};

export default Testimonial;
