import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const Carriers = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return null;
};

const ServiceIndustries = ({ industryData, heading, subHeading, description }) => {
  return (
    <section className="industries-section-saas" style={{ paddingBottom: "10px" }}>
      <div className="container d-flex justify-content-center flex-column align-items-center">
        <div className="OurCommitment-text d-flex text-center flex-column align-items-center" style={{ marginBottom: "65px", justifyContent: "center", display: "flex" }}>
          <h4>{subHeading}</h4>
          <h2>{heading}</h2>
          <p>{description}</p>
        </div>
        <div className="industries-section-saas-main-div-parent">
          <div className="row justify-content-center industries-section-saas-main-div">
            {industryData.map((industry, index) => (
              <div
                key={index}
                className="col-md-4 col-6 industries-section-saas-child-div text-center"
                // style={{ marginBottom: '10px', paddingBottom: '5px', marginLeft: '-80px' }} // Adjust the values as needed
              >
                <img
                  src={industry.img}
                  alt={industry.title || "Industry Image"}
                  className="industries-section-saas-child-div-img"
                  loading="lazy"
                />
                <p style={{ paddingBottom: "50px" }} className="industries-section-saas-para">
                  {industry.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceIndustries;
