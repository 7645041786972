import Bannnerimage from "../../../images/CaseStudiesImagesLogo/YouTubeLogo.webp";
import Logo from "../../../images/logo/logo_lakebrains.webp";

const data = [
  {
    slugurl: "/Youtube-Extra-Ad-Chrome-Extension",
    data: [
      {
        Banner: [
          {
            sector:" Digital Advertising/ Online Marketing",
            headingtext: "Youtube Extra Ad",
            headingpara:
              "Youtube Extra ad is a chrome extension which can show custom ads on youtube after a given time. After playing the ad for 30 sec it will resume the original video.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Custom ads on youtube with Youtube Extra Ad Chrome extension",
        casestudy: {
          Problem:
           ` "The "Youtube Extra Ad" Chrome extension faces challenges in displaying custom ads on YouTube videos, including issues with multiple tabs/windows, full-screen mode, and styling of buttons. It also needs improvements in ad targeting and seamless video transitions."`,
          challenge: [
            {
              heading: "Features :-",
              challengeli: [
                {
                  First: "Overlay a rectangle shadow on the bottom right corner of the YT player.",
                },
                {
                  First:"Display a text (Ad in 5) after a fixed time of 30 seconds which is hard coded in extension.",
                },
                {
                  First :"Youtube video gets paused after 5 sec.",
                },
                {
                  First:"Injected video(Ad) will be played in an iframe which is coming from another youtube url which is defined in hardcoded way in extension.",
                },
                {

                  First:"After 30 sec the injected video will be removed and the existing video will resume. This 30 sec is currently hard coded in the extension.",
                }

              ],
            },
            {
              heading: "Scope of future improvements:",
              challengeli: [
                {
                  First: "Currently It is not working properly for multiple tabs/windows like:",
                  Secound: "When we open youtube on a second tab it won't work.",
                },
                {
                  First: "The current implementation is not working for full screen mode.",
                },
                {
                  First: "Need to apply styling on the button.",
                },
               
              ],
            },
            {
              heading: "The Challenges :-",
              challengeli: [
                {
                  First:"Show ad in {5,4,3,2,1} tab on the current video after a given time spent on youtube",
                },
                {
                  First:"Pause the current youtube by using javascript and inject other video iframe on the current video.",
                },
                {
                First :"Remove the iframe and Play the current video again.",
                }
              ],
            },
            {
              heading: "Impact",
              challengeli: [ {First:`The solutions implemented for the extension address these challenges, resulting in improved ad display, better user experience, and increased revenue potential for advertisers and content creators. The extension now works reliably across different browsing scenarios, resolves styling issues, and enhances ad targeting and video transitions for a more engaging and effective advertising experience on YouTube.`},],
            },
          ],
        },

        companyinfo: {
            logourl: "",
          about: "About ",
          aboutpara:
            " Youtube Extra ad is a chrome extension which can show custom ads on youtube after a given time. After playing the ad for 30 sec it will resume the original video. It is developed as Poc. ",
          companysector: [
            {
              first: "Industry:",
              secound: "Entertainment",
            },
            {
              first: "Location:",
              secound: " United States",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
