import React from "react";
import responsibilityTrust from "../../../../images/NewHomePage/responsibilityTrust.svg";
import AutonomyWorking from "../../../../images/NewHomePage/AutonomyWorking.svg";
import HealthyCriticism from "../../../../images/NewHomePage/HealthyCriticism.svg";
import AttitudeKnowing from "../../../../images/NewHomePage/AttitudeKnowing.svg";
import steve_comma from "../../../../images/NewHomePage/steve_comma.svg";
import Polygon from "../../../../images/NewHomePage/Polygon.svg";
import "./OurCulture.css";

const OurCulture = () => {
  const data = [
    {
      para: "Values of freedom, responsibility and trust",
      alt: "responsibilityTrust",
      image: responsibilityTrust,
    },
    {
      para: "Autonomy of working but on time and high quality delivery",
      alt: "AutonomyWorking",
      image: AutonomyWorking,
    },
    {
      para: "Healthy criticism and honest feedback",
      alt: "HealthyCriticism",
      image: HealthyCriticism,
    },
    {
      para: "Attitude of not knowing everything and always improving",
      alt: "AttitudeKnowing",
      image: AttitudeKnowing,
    },
  ];

  return (
    <div className="OurCulture-main container-fluid d-flex flex-column">
      <div className="OurCulture-comma d-flex align-items-center justify-content-center">
        <div className="steve_commaImg position-relative">
          <img
            src={steve_comma}
            alt="steve_comma1"
            className="steve_comma1"
            loading="lazy"
          />
          <img
            src={steve_comma}
            alt="steve_comma2"
            className="steve_comma2"
            loading="lazy"
          />
        </div>
        <div className="steve_commaText">
          <h4>
            “The design is not just what it looks like and feels like. The
            design is how it works”
          </h4>
          <p>~Steve Jobs</p>
        </div>
        <div className="steveBox-Tringle">
          <img src={Polygon} alt="Polygon" loading="lazy" />
        </div>
      </div>
      <div className="OurCulture-main-container d-flex flex-column align-items-center justify-content-center container">
        <div className="OurCommitment-text d-flex text-center flex-column align-items-center">
          <h4>Our Culture</h4>
          <h2>Freedom, Trust, Quality, Growth</h2>
          <p>
            Empowering innovation through a culture of freedom, responsibility,
            trust, and continuous growth.
          </p>
        </div>
        <div className="OurCulture-Freedom d-flex justify-content-between">
        {data.map((items, key) => (
  <div
    id={key}
    key={key}
    className="px-3 py-4 OurCulture-parent"
  
  >
    <div className="p-4 pt-3">
      <img
        style={{ width: "100%" }}
        src={items.image}
        alt={items.alt}
        loading="lazy"
      />
    </div>
    <p
  style={{
    marginTop: key === 0 || key === 2 ? "46px" : key === 3 ? "27px" : "0px",
  }}
>
  {items.para}
</p>

  </div>
))}

        </div>
      </div>
    </div>
  );
};

export default OurCulture;
