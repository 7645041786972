import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ProblemSolving.css";
import { Link } from "react-router-dom";
import { FaArrowCircleRight, FaArrowCircleLeft } from 'react-icons/fa';
import AOS from "aos";

const ProblemSolving = ({ ProblemSolvingData }) => {
    useEffect(() => {
        AOS.init();
    }, []);

    const evenItems = ProblemSolvingData.filter((items, index) => index % 2 === 0);
    const oddItems = ProblemSolvingData.filter((items, index) => index % 2 !== 0);


    function TextContainer({ description }) {
        const truncatedText = description.length > 100 ? description.slice(0, 100) + '...' : description;
    
        return <div>{truncatedText}</div>;
    }
    return (
        <>
            <div className="ProblemSolving-Container">
            <hr style={{border: "1px solid rgba(18, 17, 17, 0.3)"}}/>
                <div className="OurCommitment-text d-flex text-center flex-column align-items-center" style={{ marginBottom: "5vh" ,marginTop:"4vh" }}>
                    <h4>Case Study</h4>
                    <h2>Problems We Solve</h2>
                    <p>One of the most notable examples is the rise of artificial intelligence (AI). Lakebrains helped startups to automate various tasks, such as customer service, marketing, sales through AI.</p>
                </div>
              

                <Carousel
                    autoFocus={true}
                    autoPlay={false}
                    infiniteLoop={true}
                    interval={4000}
                    emulateTouch={false}
                    preventMovementUntilSwipeScrollTolerance={true}
                    stopOnHover={true}
                    showThumbs={false}
                    showStatus={false}
                    useKeyboardArrows
                    className="container Carousel-desktop"
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <button onClick={onClickHandler} className="custom-button ProblemSolving-PreClick prev-button">
                                <FaArrowCircleLeft size={32} />
                            </button>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <button onClick={onClickHandler} className="custom-button ProblemSolving-NextClick next-button">
                                <FaArrowCircleRight size={32} />
                            </button>
                        )
                    }
                >
                    {evenItems.map((item, key) => (
                        <div id={key} key={key} className="row">
                            <div className="ProblemSolving-main container col-5">
                                <div className="ProblemSolving-testimonial-Person">
                                    <div className="ProblemSolving-Person-img-div">
                                        <img className="ProblemSolving-Person-img " src={evenItems[key].photo} alt="slide" loading="lazy" />
                                    </div>
                                    <div className="ProblemSolving-Person-text">
                                        <h4>{evenItems[key].subHeading}</h4>
                                        <h2 className="">{evenItems[key].heading}</h2>
                                        <span className=""><TextContainer description={evenItems[key].description} /></span>
                                        <Link target="_parent" to={evenItems[key].caseurl}>
                                            <button>{evenItems[key].btntext}</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="ProblemSolving-main container col-5">
                                <div className="ProblemSolving-testimonial-Person">
                                    <div className="ProblemSolving-Person-img-div">
                                        <img className="ProblemSolving-Person-img w-100" src={oddItems[key].photo} alt="slide" loading="lazy" />
                                    </div>
                                    <div className="ProblemSolving-Person-text">
                                        <h4>{oddItems[key].subHeading}</h4>
                                        <h2 className="">{oddItems[key].heading}</h2>
                                        <span className=""><TextContainer description={oddItems[key].description} /></span>
                                        <Link target="_parent" to={oddItems[key].caseurl}>
                                            <button>{oddItems[key].btntext}</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
                <div className="ProblemSolving-button d-flex flex-column align-items-center mt-5">
                    <Link target="_parent" to="https://lakebrains.com/casestudy/">
                        <button>View all</button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ProblemSolving;
