import React, { useEffect, useState } from "react";
import ScheduleCall from "../../../Comman/ScheduleCall/ScheduleCall";
import "./casestudy.css";

const Casebanner = ({ data }) => {
  const [Data, setData] = useState([]);
  useEffect(() => {
    setData(data);
  }, []);

  return (
    <>
      {/* Floating Circles */}
  
      <div className="Top-div Top-div-right"></div>
      <div className="Top-div Top-div-center"></div>
      <div className="Top-div Top-div-left"></div>
  

      {Data.map((items, index) => {
        const data1 = items.casestudy.challenge;
        const data2 = items.companyinfo;
        const customer = items.customer;
        const banner = items.Banner;

        return (
          <>
            {/* Banner */}
            <div className="container-fluid casestudy-bg p-0" key={index}>
              <div className="container">
                <div className="casestudy-banner-wrapper">
                  {banner.map((items, index) => (
                    <div className="row my-5 py-5" key={index}>
                      <div className="align-items-center col-12 col-md-6 d-flex justify-content-center order-2 order-md-1">
                        <div className="caseheader-wrapper-text">
                          <p>{items.sector}</p>
                          <h1>{items.headingtext}</h1>
                          <p>{items.headingpara}</p>
                        </div>
                      </div>
                      <div className="align-items-center col-12 col-md-6 d-flex justify-content-center order-1 order-md-2">
                        <div className="caseheader-image-wrapper">
                          <div className="img-wrapeer">
                            <img src={items.image} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Header */}
            <div className="container-fluid p-0">
              <div className="container">
                <div className="example-casestudy">
                  <div className="casestudyheading my-5">
                    <div className="mainheading text-center">
                      <h2 className="w-75 mx-auto">{items.heading}</h2>
                    </div>
                  </div>
                  <div className="casestudy-discription">
                    <div className="row my-5">
                      <div className="col-md-8 ">
                        <div className="maincontentwrappper">
                          <div className="casestyudymain-content">
                            <div className="problem-para">
                              <p>{items.casestudy.Problem}</p>
                            </div>
                          </div>
                          {data1.map((ul, index) => (
                            <div className="thechallenge mt-4 pt-3" key={index}>
                              <h5>{ul.heading}</h5>
                              <ul>
                                {ul.challengeli.map((li, index) => (
                                  <React.Fragment key={index}>
                                    <li>{li.First}</li>
                                    {li.Secound !== undefined && (
                                      <ul>
                                        <li>{li.Secound}</li>
                                      </ul>
                                    )}
                                  </React.Fragment>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="case-company-info">
                          {/* Logo */}
                          <div className="companylogo-case">
                            <div className="logocontainer" style={{marginTop:"-9vh"}}>
                              <img src={data2.logourl} alt="" />
                            </div>
                          </div>
                          {/* About */}
                          {data2.videoUrl && (
                            <div className="company-info">
                              <iframe
                                src={data2.videoUrl}
                                width="350px"
                                height="200px"
                                title="CaseStudiesVideo"
                                allowFullScreen
                              />
                            </div>
                          )}
                          <div className="company-info">
                            <h3>{data2.about}</h3>
                            <p>{data2.aboutpara}</p>
                          </div>
                          {/* Sector */}
                          <div className="companysector-casestudy">
                            <div className="companysector">
                              {data2.companysector.map((li, index) => (
                                <p key={index}>
                                  <span>{li.first} </span>
                                  {li.secound}
                                  {["https://www.interactpro.ai/", "https://github.com/lakebrains-ind/browser-control-through-voice", "https://chrome.google.com/webstore/detail/live-transcript-extension/bbdgdpkamhamfdcbbkbooomiliaiahpn", "https://idealeads.in/", "https://www.meinshort.com/"].includes(li.url) ? (
                                    <a target="blank_" href={li.url}>
                                      {li.name}
                                    </a>
                                  ) : null}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Customer Feedback */}
            <div className="container-fluid p-0">
              <div className="mt-5"></div>
              <div className="container">
                <div className="customer-feedback-wrapper">
                  {customer.map((items, index) => (
                    <React.Fragment key={index}>
                      <div className="feedback-container">
                        <p>{items.feddbackpara}</p>
                      </div>
                      <div className="customer-information mb-5 pb-5">
                        <div className="image-container">
                          <img src={items.image} alt="" />
                        </div>
                        <h2>{items.name}</h2>
                        <h4>{items.position}</h4>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </>
        );
      })}
      {/* <ScheduleCall /> */}
    </>
  );
};

export default Casebanner;
