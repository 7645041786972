import React from "react";

// CSS
import "@fontsource/raleway";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./components/styles/General.css";
import "./components/styles/Pages.css";
import {} from "./components/styles/Responsive.css";
import AppRoutes from "./components/routes/routes";
import "./components/overwrite.css";
import "@fontsource/lato";
import FAQ from "./components/Comman/FAQ/FAQ";
import  "./App.css";
const App = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
};

export default App;

// "gsap": "^1.20.6",
// "jquery": "^3.6.1",