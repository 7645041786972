import React, { useState, useEffect } from "react";
import hello from "../../../../images/Products/Idealeads/landing3-slider-pic2.webp";

import Popup2 from "./Popup2";

const  BannerComponent = ({ Data }) => {
  const [toggle, settoggle] = useState(0);
  const [popupstate, setPopupstate] = useState(false);

  const [section, setsection] = useState("");

  useEffect(() => {
    setsection(Data);

    // const newFun = async(params) => {
    //   let images = await require(`../../../../images/Products/${Data.image}`)
    //   console.log(images);
    //   setsection({...Data,image : images})
    //   console.log(images);
    // }

    // newFun();
  }, [Data]);

  const handletoggle = (e) => {
    settoggle(e);
    setPopupstate(true);
  };
  return (
    <div className="container-fliud justify-content-center align-items-center d-flex LakeHome--Wrapper-000-0">
      <div className="container">
        <div className=" py-4 mx-3 Product-Carousel-Container">
          <div className="col-md-6 order-md-1 order-2 Lake-Case-Carousel-Wrapper d-flex justify-content-center align-items-center">
            <div className="row LakeHome-CaseS-Heading  text-left ">
              <div className="banner-content-product">
                <h1 className="">{section?.title}</h1>
                <h6 className="mt-5">{section?.subTitle}</h6>
                <div className="mt-5" style={{ maxWidth: "150px" }}>
                  <div
                    className="col-lg-6 our-test-heading-t-0 text-left "
                    onClick={() => handletoggle(1)}
                  >
                    <span className="btn-primary btn-product header-blogs-btn btn position-relative">
                      {section?.button?.text}{" "}
                    </span>
                  </div>
                  <span className="">
                    {toggle === 1 ? (
                      <Popup2
                        trigger={popupstate}
                        setTrigger={(e) => setPopupstate(e)}
                      ></Popup2>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 lakeH-banner-nlp  d-flex justify-content-center align-items-center order-1">
            {/* <div className="Lake-Case-Carousel-ImageWrapper">
              <div className="Lake-Case-Carousel-image"> */}
                <img src={section?.image} alt="" srcSet="" />
              {/* </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
