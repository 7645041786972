import React from "react";
// import { PopupButton } from "react-calendly";
import "./saasProductPage.css";

import TechStackSaas from "./techStackSaas";
import ProblemSolvingImg from "../../../../images/SubPages/OurTestimonials/ProblemSolvingImg.png";

import ServiceOurBenefits from "../../../Comman/ServiceComponents/SaasProductService/ServiceOurBenefits";
import ServiceFaq from "../../../Comman/ServiceComponents/SaasProductService/ServiceFaq";
import ConnectOurExperts from "../../../Comman/ServiceComponents/SaasProductService/ConnectOurExperts";
import ServiceIndustries from "../../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";
import Frame1 from "../../../../images/chrome-extension-develpment/Frame1.svg";
import Frame2 from "../../../../images/chrome-extension-develpment/Frame2.svg";
import Frame3 from "../../../../images/chrome-extension-develpment/Frame3.svg";
import Frame5 from "../../../../images/chrome-extension-develpment/Frame5.svg";
import Frame6 from "../../../../images/chrome-extension-develpment/Frame6.svg";
import Frame7 from "../../../../images/chrome-extension-develpment/Frame7.svg";

import execution1 from "../../../../images/chrome-extension-develpment/execution1.svg";
import execution2 from "../../../../images/chrome-extension-develpment/execution2.svg";
import execution3 from "../../../../images/chrome-extension-develpment/execution3.svg";
import execution4 from "../../../../images/chrome-extension-develpment/execution4.svg";
import execution5 from "../../../../images/chrome-extension-develpment/execution5.svg";
import execution6 from "../../../../images/chrome-extension-develpment/execution6.svg";

import ServiceUseCases from "../../../Comman/ServiceComponents/SaasProductService/ServiceUseCases";
import ServiceExecution from "../../../Comman/ServiceComponents/SaasProductService/ServiceExecution";
import ServiceBanner from "../../../Comman/ServiceComponents/SaasProductService/ServiceBanner";
import Services from "../../../Comman/ServiceComponents/SaasProductService/Services";

import SubjectMatterExperts from "../../../../images/chrome-extension-develpment/SubjectMatterExperts.svg";
import AutomatedTestProcess from "../../../../images/chrome-extension-develpment/AutomatedTestProcess.svg";
import IntegratedServices from "../../../../images/chrome-extension-develpment/IntegratedServices.svg";
import Support24 from "../../../../images/chrome-extension-develpment/Support24.svg";
import ProblemSolving from "../../../Comman/NewHomeComponent/ProblemSolving/ProblemSolving";
import AIMLHomeImage from "../../../../images/chrome-extension-develpment/AIML.svg";

// AIML logos
import tensorflow from "../../../../images/OurtechStack/tensorflow.webp";
import pytorch from "../../../../images/OurtechStack/pytorch.webp";
import openai from "../../../../images/OurtechStack/openai.svg";
import langchain from "../../../../images/OurtechStack/langchain.jpeg";
import lama from "../../../../images/OurtechStack/lama.png";
import pandas from "../../../../images/OurtechStack/pandas1.png";
import numpy from "../../../../images/OurtechStack/numpy.svg";
import palm from "../../../../images/OurtechStack/palm.png";
import kalman from "../../../../images/OurtechStack/kalman.png";
import bard from "../../../../images/OurtechStack/bard.png";
import Computervision from "../../../../images/OurtechStack/computervision.png";
import GenAI from "../../../../images/OurtechStack/GenAI.png";
import NLP from "../../../../images/OurtechStack/nlp.png";

//computer vision
import opencv from "../../../../images/OurtechStack/opencv.png";
import sift from "../../../../images/OurtechStack/sift.png";
import yolo from "../../../../images/OurtechStack/yolo.png";
import resnet from "../../../../images/OurtechStack/resnet.png";
// import sift from "../../../../images/OurtechStack/sift.png";

//Audio AI
import markov from "../../../../images/OurtechStack/markov.png";
import neural from "../../../../images/OurtechStack/neural.png";

//OurExpert
import Anirban from "../../../../images/ourExperts/anirban.png";

// import ProblemSolvingImg from "../../../../images/SubPages/OurTestimonials/ProblemSolvingImg.png";
import LiveTranscript from "../../../../images/SubPages/OurTestimonials/LiveTranscript.png";
import Floomy from "../../../../images/SubPages/OurTestimonials/Floomy.png";
import Interact from "../../../../images/SubPages/OurTestimonials/Interact_copy.png";
import Gnote from "../../../../images/SubPages/OurTestimonials/Gnote.png";

// industries data
import Vector3 from "../../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../../images/chrome-extension-develpment/recruitment.png";

const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];

const execution = [
  {
    id: "1",
    img: execution1,
    title: "Understanding the business problem",
  },
  {
    id: "2",
    img: execution2,
    title: "Data gathering",
  },
  {
    id: "3",
    img: execution3,
    title: "EDA and feature engineering",
  },
  {
    id: "6",
    img: execution4,
    title: "Model scaling",
  },
  {
    id: "5",
    img: execution5,
    title: "Model deployment",
  },

  {
    id: "4",
    img: execution6,
    title: "Model building and tuning",
  },
];

const UseCases = [
  {
    img: Frame1,
    title: "Detects Cancer",
    description:
      "Artificial intelligence (AI) is being used to detect and diagnose cancer. Researchers are using machine learning to build tools that can potentially catch tumors or lesions that doctors could miss.    ",
  },
  {
    img: Frame2,
    title: "Self Driving",
    description:
      " Self-driving cars rely on AI, including data from sensors, like Google Street View and onboard cameras, to make real-time decisions. Using deep learning, they mimic human decision-making to control actions like steering and braking. ",
  },
  {
    img: Frame3,
    title: "Fraudulent Transactions",
    description:
      "AI detects and prevents online fraud in banking, credit cards, and e-commerce by processing vast data quickly and accurately, outperforming humans. It adapts to transaction complexity, offering scalability.    ",
  },
  // {
  //   img: Frame4,
  //   title: "Spam Email",
  //   description: "AI-driven spam emails personalize content with recipient details, crafting messages that mimic genuine emails with sophisticated language, making them hard to distinguish from legitimate ones.    ",
  // },
  {
    img: Frame5,
    title: "DNA Sequencing",
    description:
      "AI can help speed up diagnosis and the search for better treatments. It can also be used to identify synthetic DNA sequences that could be useful in biotechnology and medicine.    ",
  },
  {
    img: Frame6,
    title: "Financial Monitor",
    description:
      "Artificial intelligence (AI) in finance is the use of machines to perform tasks that help businesses analyze, manage, and invest their capital. AI can automate repetitive tasks, detect anomalies, and provide real-time recommendations. ",
  },

  {
    img: Frame7,
    title: "Algorithmic Trading",
    description:
      "AI algorithmic trading is a systematic approach to buying and selling in financial markets. AI algorithms analyze market data, news sentiments, and other factors to make split-second trading decisions.",
  },
];

const OurBenefitsData = [
  {
    para: "Subject matter experts",
    alt: "SubjectMatterExperts",
    image: SubjectMatterExperts,
  },
  {
    para: "Automated test process",
    alt: "AutomatedTestProcess",
    image: AutomatedTestProcess,
  },
  {
    para: "Integrated services",
    alt: "IntegratedServices",
    image: IntegratedServices,
  },
  {
    para: "24 hrs support",
    alt: "Support24",
    image: Support24,
  },
];

const ProblemSolvingData = [
  {
    subHeading: "Productivity/ Technology",
    heading: "GNote",
    photo: Gnote,
    description:
      "So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.",
    btntext: "View More",
    caseurl: "/casestudy/Gnote/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Interact",
    photo: Interact,
    description:
      "So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.",
    btntext: "View More",
    caseurl: "/casestudy/interact-extension/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Live Transcript Extension",
    photo: LiveTranscript,
    description:
      "So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.",
    btntext: "View More",
    caseurl: "/casestudy/capture-transcript-from-a-meet/",
  },
  {
    subHeading: "Productivity/ Technology",
    heading: "Floomy",
    photo: Floomy,
    description:
      "So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.",
    btntext: "View More",
    caseurl: "/casestudy/floomy/",
  },
];
const data1 = [
  {
    name: "NLP / Gen AI ",
    info: [
      {
        logo: openai,
        name: "Open AI",
        alt: "Open AI",
        link: "https://openai.com/",
      },
      {
        logo: lama,
        name: "Llama",
        alt: "Llama",
        link: "https://ai.meta.com/blog/large-language-model-llama-meta-ai/",
      },
      {
        logo: bard,
        name: "Bard",
        alt: "Bard",
        link: "https://bard.google.com/chat",
      },
      // {
      //   logo: falcon,
      //   name: "Falcon",
      //   alt: "Falcon",
      //   link: "https://falconllm.tii.ae/",
      // },
      {
        logo: palm,
        name: "PaLM 2",
        alt: "PaLM 2",
        link: "https://ai.google/discover/palm2/",
      },
      {
        logo: langchain,
        name: "LangChain",
        alt: "LangChain",
        link: "https://www.langchain.com/",
      },

      {
        logo: pandas,
        name: "Pandas",
        alt: "Pandas",
        link: "https://pandas.pydata.org/",
      },
      {
        logo: tensorflow,
        name: "Tensorflow",
        alt: "Tensorflow",
        link: "https://pandas.pydata.org/",
      },
      {
        logo: pytorch,
        name: "pytorch",
        alt: "pytorch",
        link: "https://pytorch.org/",
      },
      {
        logo: numpy,
        name: "Numpy",
        alt: "Numpy",
        link: "https://numpy.org/",
      },
    ],
  },
  {
    name: "Computer Vision",
    info: [
      {
        logo: opencv,
        name: "OpenCV",
        alt: "OpenCV",
        link: "https://opencv.org/",
      },
      {
        logo: kalman,
        name: "Kalman Filter",
        alt: "Kalman Filter",
        link: "https://deepai.org/machine-learning-glossary-and-terms/kalman-filter",
      },
      {
        logo: yolo,
        name: "Yolo",
        alt: "Yolo",
        link: "https://www.v7labs.com/blog/yolo-object-detection",
      },
      {
        logo: sift,
        name: "Sift",
        alt: "Sift",
        link: "https://sift.com/",
      },
      {
        logo: resnet,
        name: "ResNet",
        alt: "ResNet",
        link: "https://en.wikipedia.org/wiki/Residual_neural_network",
      },
    ],
  },
  {
    name: "Audio AI",
    info: [
      {
        logo: markov,
        name: "Markov Model",
        alt: "Markov Model",
        link: "https://en.wikipedia.org/wiki/Markov_model",
      },
      {
        logo: pandas,
        name: "Gaussian Mixture Model",
        alt: "Gaussian Mixture Model",
        link: "https://pandas.pydata.org/",
      },
      {
        logo: pandas,
        name: "Pattern Matching Algorithms",
        alt: "Pattern Matching Algorithms",
        link: "https://pandas.pydata.org/",
      },
      {
        logo: neural,
        name: "Neural Networks",
        alt: "Neural Networks",
        link: "https://en.wikipedia.org/wiki/Artificial_neural_network",
      },
      {
        logo: pandas,
        name: "Active Noise Cancelling",
        alt: "Active Noise Cancelling",
        link: "https://pandas.pydata.org/",
      },
      {
        logo: pandas,
        name: "Vector Quantization",
        alt: "Vector Quantization",
        link: "https://en.wikipedia.org/wiki/Vector_quantization",
      },
      {
        logo: pandas,
        name: "Decision Trees",
        alt: "Decision Trees",
        link: "https://en.wikipedia.org/wiki/Decision_tree",
      },
    ],
  },
];

const Service = [
  {
    id: "1",
    Heading:
      "How does AI help travel companies with custom packaging, making vacations more personalized?",
    Answer:
      'AI analyzes travel preferences and creates tailor-made packages. Fact: 70% of travelers prefer personalized experiences. Indian travel agency "Xplorer Voyages" increased customer satisfaction by 30% using AI for custom packages.',
  },
  {
    id: "2",
    Heading:
      "How can AI assist doctors in providing better healthcare services?",
    Answer:
      "AI helps doctors manage patient records and offers precise diagnoses. Fact: AI can diagnose diseases with 95% accuracy. Indian clinic 'MediCare India' reduced paperwork by 50% using AI, enhancing patient care.",
  },
  {
    id: "3",
    Heading: "How does AI enhance online shopping experiences for customers?",
    Answer:
      "AI suggests products, improves customer service, and predicts trends. Fact: AI-powered chatbots increase customer inquiries by 80%. Indian e-commerce store 'Trendy Threads' boosted sales by 35% with AI-driven product suggestions.",
  },
  {
    id: "4",
    Heading: "How does AI streamline the life insurance process for customers?",
    Answer:
      "AI automates policy management and provides instant customer support. Fact: AI reduces claims processing time by 50%. Indian insurer 'SecureLife' expedited claims by 40% using AI, ensuring customer satisfaction.  ",
  },
  {
    id: "5",
    Heading:
      "How does AI aid architects and designers in creating innovative spaces?",
    Answer:
      "AI designs spaces efficiently and sustainably, visualizing ideas. Fact: AI-generated designs save 30% in construction costs. Indian design firm 'InnoSpace Designs' implemented AI, leading to 25% faster project completion.",
  },
  {
    id: "6",
    Heading: "How does AI enhance IT solutions for businesses?",
    Answer:
      "AI automates tasks, predicts system issues, and ensures cybersecurity. Fact: AI-driven IT solutions increase efficiency by 60%. Indian tech company 'TechPro Solutions' improved client satisfaction by 45% with AI-enhanced services.",
  },
  {
    id: "7",
    Heading:
      "How does AI optimize logistics operations and improve delivery efficiency?",
    Answer:
      "AI analyzes data for better inventory management and route optimization. Fact: AI reduces logistics costs by 20%. Indian logistics firm 'SwiftCargo' reduced delivery time by 30% using AI algorithms, ensuring timely deliveries.",
  },
  {
    id: "8",
    Heading:
      "In what manner does AI support architects and interior designers?",
    Answer:
      "AI aids architects by optimizing designs based on client preferences and space analysis. Indian interior design firm, Studio Elements, reduced project completion time by 40% using AI, ensuring innovative designs aligned with client expectations.",
  },

  {
    id: "9",
    Heading: "How can AI optimize processes in chemical manufacturing?",
    Answer:
      "AI-driven analytics enhance chemical manufacturing efficiency by predicting maintenance needs and optimizing production schedules. Indian chemical company, Reliance Industries, increased production by 15% while reducing energy consumption by 20% through AI-driven process optimization, leading to substantial cost savings.",
  },
  {
    id: "10",
    Heading: "What happens if there's a service outage in the cloud?    ",
    Answer:
      "Cloud service providers aim to ensure high availability, but outages can still occur. It's essential to have a business continuity and disaster recovery plan in place to mitigate the impact of such events.",
  },
  {
    id: "11",
    Heading: " What is serverless computing in the cloud?    ",
    Answer:
      "Serverless computing is a cloud computing model where the cloud provider manages the infrastructure, and users only pay for the computing resources they consume, without worrying about server management.    ",
  },
  {
    id: "12",
    Heading: " Can I host a website on the cloud?    ",
    Answer:
      "Yes, you can host a website on the cloud by using web hosting services offered by cloud providers. These services provide scalability, security, and reliability for hosting websites and web applications.",
  },
  {
    id: "13",
    Heading: "How can I secure my cloud resources from cyberattacks?    ",
    Answer:
      "Implement strong authentication, encryption, access controls, and regular security updates. It's also important to stay informed about the latest security threats and best practices.    ",
  },
  {
    id: "14",
    Heading:
      "What is the difference between SaaS, PaaS, and IaaS in cloud computing?    ",
    Answer:
      "SaaS (Software as a Service) delivers software applications over the internet. PaaS (Platform as a Service) provides a platform for developing, testing, and deploying applications. IaaS (Infrastructure as a Service) offers virtualized computing resources like servers and storage.    ",
  },
  {
    id: "15",
    Heading: " How can I monitor and optimize my cloud costs?",
    Answer:
      "Use cloud cost management tools to track expenses, set budgets, and regularly review your cloud usage to identify opportunities for optimization, such as resizing resources or using reserved instances.    ",
  },
];

const SubService = [
  {
    img: GenAI,
    heading: "Generative AI",
    para: "Unlock the potential of Generative AI with Lakebrains Technologies. Our expert team brings creativity to life, generating compelling content and designs that captivate your audience. With our innovative solutions, your business can stand out in the digital landscape, leaving a lasting impression on customers.",
  },
  {
    img: NLP,
    heading: "NLP",
    para: "At Lakebrains Technologies, we leverage the power of NLP to enhance communication and understanding. Our NLP solutions enable businesses to interact intelligently with users, providing seamless customer experiences. From chatbots to sentiment analysis, we transform textual data into valuable insights, empowering your business to make informed decisions.",
  },
  {
    img: Computervision,
    heading: "Computer Vision",
    para: "See the world through the lens of intelligence with Lakebrains Technologies' Computer Vision services. Our cutting-edge solutions enable machines to interpret and understand visual information, revolutionizing industries. From facial recognition to object detection, we create applications that redefine how businesses perceive and interact with the visual world.",
  },
];

const AiMlNewPage = () => {
  return (
    <>
      <ServiceBanner
        heading={
          <span>
            AI/ML <br /> Development
          </span>
        }
        subHeading="Artificial intelligence (AI) is the theory and development of computer systems capable of performing tasks that historically required human intelligence, such as recognizing speech, making decisions, and identifying patterns. AI is an umbrella term that encompasses a range of technologies, including machine learning, deep learning, and natural language processing. We provide support and development of the latest AI capabilities including Generative AI. "
        BannerImg={AIMLHomeImage}
      />

      <Services subservices={SubService} />

      <ServiceUseCases
        UseCases={UseCases}
        subHeading="USE CASES"
        heading="AI/ML Solutions Simplified"
        description="Our team of AI/ML experts is here to tackle a myriad of use-cases and even more complex challenges."
      />

      <ServiceIndustries
        industryData={industryData}
        subHeading="Industries Transformed By AI/ML"
        heading="Industries"
        description="Our experts will work with you to understand and solve specific industry problems using AI/ML."
      />

      <section>
        <TechStackSaas techDataInfo={data1} />
      </section>

      <ServiceExecution
        execution={execution}
        subHeading="EXECUTION"
        heading="Executing AI/ML Solution With Precision"
        description=" Our improved execution process helps you move faster and build a scalable solution"
      />

      <ServiceOurBenefits
        OurBenefitsData={OurBenefitsData}
        subHeading="OUR BENEFITS"
        heading="Unlock The Value Of Our Offering"
        description="We don't just deliver; we collaborate as a dedicated team, supporting you at every stage of development and beyond."
      />

      <ProblemSolving ProblemSolvingData={ProblemSolvingData} />

      <ConnectOurExperts
        OurExperts="Anirban Mandal"
        image={Anirban}
        subHeading="connect with our experts"
        heading="Let Us Start Your Project Today"
        description="Unlock the true potential of your browser with our exceptional Chrome extension plugins and AddOns. Contact LakeBrains today to get started on your journey to enhance browsing!"
      />

      <ServiceFaq serviceData={Service} />
    </>
  );
};

export default AiMlNewPage;
