import React, { useEffect } from "react";
import { PopupButton } from "react-calendly";

// import "./OurBenefits.css";

import AOS from "aos";

AOS.init();

const Carriers = () => {
    useEffect(() => {
        AOS.init();
    }, []);
};

const ServiceBanner = ({ heading, subHeading, BannerImg }) => {
    return (
        <>
            <section className="">
                <div className=" d-flex p-0 container">
                    <div className="row"></div>
                    <div className="home-main">
                        <div className="home-text col-lg-7 col-md-12 ">
                            <h1 className="home-heading">{heading} </h1>
                            <div className="line"></div>
                            <span className="home-p">{subHeading} </span>
                            <div className="home-btns">
                                <PopupButton
                                    className="btn-1 btn-link Hbtn-1" 
                                    url="https://calendly.com/khushbu-jpao/opportunity"
                                    rootElement={document.getElementById("root")}
                                    text="Schedule Meeting"
                                />
                            </div>
                        </div>
                        <div className="home-img col-lg-5 col-md-12">
                            <img className="img-fluid HI" alt="CE" src={BannerImg} loading="lazy"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceBanner;
