import axios from "axios";
import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import ReCAPTCHA from "react-google-recaptcha";
import "./ChatGptQandA.css";
import { SuperSEO } from "react-super-seo";

const fileTypes = ["PDF"];

const ChatGptQandA = () => {
  const [file, setFile] = useState(null);

  let allData = [];

  const [questionData, setQuestionData] = useState({});
  const [answerData, setAnswerData] = useState([]);
  const [emailSaved, setEmailSaved] = useState(false);
  const [questionAnswerData, setQuestionAnswerData] = useState([]);
  const [customText, setCustomText] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");

  const [ansExpand, setAnsExpand] = useState(false);
  const [error, setError] = useState("");

  let captcha;

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };

  const resetCaptcha = () => {
    captcha.reset();
  };

  function onChange(value) {
    // console.log("SAS", value);
    document.querySelector("#uploadBtn").style.display = "block";
  }

  const handleChange = (file) => {
    setFile(file);
  };

  const handleChangeText = (e) => {
    setCustomText(e.target.value);
  };

  const getQuestion = async (e) => {
    console.log("clicked");
    document.querySelector("#loader_empthy_chatGpt").style.display = "flex";
    e.preventDefault();
    console.log(file);

    setQuestionData({});
    setAnswerData([]);

    var bodyFormData = new FormData();
    bodyFormData.append("pdf", file[0]);

    await axios({
      method: "post",
      // url: "http://localhost:8080/pdfAnalyzer/get-question",
      url: "https://lb-backend-pj5xuob5ca-el.a.run.app/pdfAnalyzer/get-question",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (response) => {
        //handle success
        // console.log("response.data ---------> \n", response.data);
        document.querySelector("#uploadBtn").style.display = "none";

        document.querySelector("#loader_empthy_chatGpt").style.display = "none";
        if (response.data.q.questionsAndAnswers)
          setQuestionAnswerData(response.data.q.questionsAndAnswers);
        else if (response.data.q.questions)
          setQuestionAnswerData(response.data.q);
        else setQuestionAnswerData(response.data.q);

        setQuestionData(response.data);
        setEmailSaved(false);
      })
      .catch(function (error) {
        //handle error
        console.log(error);
      });
  };

  const getAnswer = async (e) => {
    e.preventDefault();
    setError("");
    let bodyFormData = {
      text: questionData.summary,
      question: customText,
    };

    await axios({
      method: "post",
      // url: "http://localhost:8080/pdfAnalyzer/get-answer",
      url: "https://lb-backend-pj5xuob5ca-el.a.run.app/pdfAnalyzer/get-answer",
      data: JSON.stringify(bodyFormData),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        //handle success
        // console.log(response);
        if (response.data.ans !== "") {
          setAnswerData((answerData) => [
            ...answerData,
            { que: customText, ans: response.data.ans },
          ]);
        } else {
          setError(
            "Please ask a valid Question related to Summary with a question mark"
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  //
  const saveMailToDb = (e) => {
    e.preventDefault();
    // console.log("enteredEmail", enteredEmail);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: `http://localhost:8080/pdfAnalyzer/get-user-email?email=${enteredEmail}`,
      url: `https://lb-backend-pj5xuob5ca-el.a.run.app/pdfAnalyzer/get-user-email?email=${enteredEmail}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setEmailSaved(true);
        setEnteredEmail("");
        setFile(null);
        resetCaptcha();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <SuperSEO
        title="ChatGptQandA - Chat-based Question and Answer System"
        description="ChatGptQandA is a chat-based question and answer system built using React. It allows users to upload a PDF file, extract the summary, and ask questions related to the summary. The system retrieves relevant answers using an API and displays them in an accordion-style interface. Users can also ask custom questions and receive answers. Try ChatGptQandA for an interactive Q&A experience "
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage:
              "https://www.youtube.com/watch?v=wZ02FUjCYHw&t=3s&ab_channel=LakeBrainsTechnologies",
            summaryCardImageAlt: "video",
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
      <div
        className="background-img"
        style={{ padding: "100px 0px", minHeight: "45vh" }}
      >
        <div id="#loading" className="container">
          <div>
            <div className="" id="loader_empthy_chatGpt">
              <div className="wrapper">
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
              </div>
              <div className="text">Please wait</div>
            </div>
            <div style={{ margin: "10px 0px", textAlign: "center" }}>
              Choose a PDF File
            </div>
            <div
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FileUploader
                className="px-3"
                multiple={true}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
              {file ? (
                <div className="px-3">
                  <button
                    id="uploadBtn"
                    type="button"
                    className="btn"
                    onClick={(e) => {
                      getQuestion(e);
                    }}
                    style={{ display: "none" }}
                  >
                    Upload
                  </button>
                </div>
              ) : null}
            </div>
            {/* {file ? ( */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReCAPTCHA
                ref={(r) => setCaptchaRef(r)}
                sitekey="6Ldo4NoqAAAAAOpqYaHw0wHweg3Gg5rR2Z_AI3Jb"
                onChange={onChange}
                theme="light"
              />
            </div>
            {/* ) : null} */}

            <p style={{ textAlign: "center" }}>
              {file ? `File name: ${file[0].name}` : "no files uploaded yet"}
            </p>
            {questionData.summary ? (
              <div>
                {!emailSaved && (
                  <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
                    <p className="mb-4">Please enter your email to reveal:</p>
                    <div className="form-group mb-4 w-50">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Enter your email"
                        required
                        onChange={(e) => {
                          setEnteredEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group mb-4 w-50">
                      {enteredEmail.includes("@") &&
                        enteredEmail.includes(".") && (
                          <button
                            className="btn btn-primary w-100"
                            onClick={(e) => {
                              saveMailToDb(e);
                            }}
                          >
                            Submit
                          </button>
                        )}
                    </div>
                  </div>
                )}
                {emailSaved && (
                  <div className="my-5">
                    <div className="">
                      <h4 className="my-5 text-center">Summary</h4>
                      <div className="mainText">
                        <p>{questionData.summary}</p>
                      </div>
                    </div>
                    <div className="">
                      {/* Collapse Section Start Here */}
                      <h4 className="my-5 text-center">Questions</h4>
                      <div className="accordion " id="accordionExample">
                        {questionAnswerData
                          ? questionAnswerData.map((items, index) => (
                              // console.log(items)
                              <div key={items.que}>
                                <div
                                  className="card-header py-0 bg-white lakeH-acc-h"
                                  id={`heading${index}`}
                                >
                                  <h2 className="mb-0 font-s-16 case_Study_list">
                                    <button
                                      className="btn btn-link btn-block collapseButton collapsed"
                                      id="collapsebtn-01"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target={`#collapse${index}`}
                                      aria-expanded="true"
                                      aria-controls=""
                                    >
                                      {/* {items.que.length > 100 ? <span>{items.que.slice(0, 100)}...</span> : items.que} */}
                                      {items.que}
                                    </button>
                                  </h2>
                                </div>

                                <div
                                  id={`collapse${index}`}
                                  className="collapse collapse-data"
                                  aria-labelledby={`heading${index}`}
                                  data-parent="#accordionExample"
                                >
                                  <div className="card-body font-s-14 pt-1 lakeH-acc-d">
                                    {/* {items.ans.slice(0, 140)} */}
                                    {!ansExpand ? (
                                      items.ans.length > 300 ? (
                                        <span>
                                          {items.ans.slice(0, 300)}...
                                        </span>
                                      ) : (
                                        items.ans
                                      )
                                    ) : (
                                      items.ans
                                    )}
                                    {items.ans.length >= 300 ? (
                                      !ansExpand ? (
                                        <button
                                          className="btn faq-readme-btn my-2"
                                          onClick={() => setAnsExpand(true)}
                                        >
                                          Read More
                                        </button>
                                      ) : (
                                        <button
                                          className="btn faq-readme-btn my-2"
                                          onClick={() => setAnsExpand(false)}
                                        >
                                          Read Less
                                        </button>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                    {
                      <div className="col-md-12 col-sm-12">
                        <div
                          style={{
                            textAlign: "center",
                            margin: "20px 0px",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}
                        >
                          Or Ask Your custom Question
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <input
                            type="text"
                            onChange={(e) => {
                              handleChangeText(e);
                            }}
                            className="mainTextInput mx-3 px-5"
                            placeholder="Custom Question"
                          />
                          <div>
                            <button
                              id="analyzeBtn"
                              type="button"
                              className="btn btn-outline-primary mx-3"
                              onClick={(e) => getAnswer(e)}
                              style={{ display: "block" }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        {error ? <p style={{ color: "red" }}>{error}</p> : null}
                      </div>
                    }
                    {answerData.length !== 0 ? (
                      <div className="col-md-12 col-sm-12">
                        <div className="mainText">
                          {answerData &&
                            answerData.map((items) => {
                              return (
                                <div>
                                  <div className="textRight">{items.que}</div>
                                  <div className="textLeft">{items.ans}</div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatGptQandA;
