import React from "react";
import data from "./DeskeTimeManager";
import BannerImage from "../../../../images/CaseStudiesImagesLogo/AIchatbox.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const DeskeTimeManager = () => {
    return (
        <>
            <SuperSEO
                title="Revolutionize Document-Based Queries with Our AI Chat Box"
                description="Our AI Chat Box provides instant and accurate answers to document-based queries, simplifying information retrieval and enhancing productivity for professionals."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { BannerImage },
                        summaryCardImageAlt: "AI Chat Box for Document-Based Queries",
                        summaryCardSiteUsername: "YourCompany",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default DeskeTimeManager;
