import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-tabs/style/react-tabs.css";
import "./AboutUs.css";

import ServiceIndustries from "../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";

import WhoWeAreMissionVission from "./teams-group/WhoWeAreMissionVission";
import Teams from "./teams-group/Teams";
import Achive from "./teams-group/Achive";
import Outings from "./teams-group/Outings";
import Banner from "./teams-group/Banner";
import OurValues from "./teams-group/OurValues";
import CompanyGoals from "../../../images/teams/Company Goals.webp";
import Rewards from "../../../images/teams/Rewards.webp";
import Certificate from "../../../images/teams/Certificate.webp";
import WellBeing from "../../../images/teams/Well Being.webp";
import Training from "../../../images/teams/Training.webp";
import Environment from "../../../images/teams/Environment.webp";
import { SuperSEO } from "react-super-seo";

//industryData
import Vector3 from "../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../images/chrome-extension-develpment/recruitment.png";


const archive = [
  {
    heading: "Company Goals",
    alt: "Company Goals",
    para: "",
    image: CompanyGoals,
  },
  {
    heading: "Rewards",
    alt: "Rewards",
    para: "",
    image: Rewards,
  },
  {
    heading: "Certificate",
    alt: "Certificate",
    para: "",
    image: Certificate,
  },
  {
    heading: "Well Being",
    alt: "Well Being",
    para: "",
    image: WellBeing,
  },
  {
    heading: "Training",
    alt: "Training",
    para: "",
    image: Training,
  },
  {
    heading: "Environment",
    alt: "Environment",
    para: "",
    image: Environment,
  },
];

const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];


const AboutUs = () => {
  return (
    <>
      <SuperSEO
        title="Lakebrains technologies"
        description="Lakebrains is the leading company in NLP (Sentiments and Behavior Analysis), Browser Extension Development, SaaS based tools, Chatbots, etc."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: "../../../images/teams/bg0.webp",
            summaryCardImageAlt: "img",
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
      <Banner />
        <WhoWeAreMissionVission />
      <ServiceIndustries industryData={industryData}  heading="Industries We Serve" description="We have served to different industrial verticals where are working as technical partners for startup and business through the power of AI." />
      <OurValues subHeading="Our Values" heading="Define How We Behave" description="Our values define who we are, what we stand for, and how we behave. Our purpose is to build trust in society and solve important problems. In an increasingly complex world, we evolve through our core value to benefit communities and society." />
      <Teams />
      <Achive archive={archive} subHeading="A Platform To Achieve" heading="Values" description="Our web development services aim not only to create beautiful apps, but we create apps with rich features, great usability, scalability and universal compatibility to increase sales." />
      <Outings />
    </>
  );
};

export default AboutUs;
