import React from "react";
import ServiceIndustries from "../../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";
// import CEOMessage from "../../../Comman/CeoMassage";

import Vector3 from "../../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../../images/chrome-extension-develpment/recruitment.png";
import CaseStudyBanner from "../../../Comman/CaseStudyBanner/CaseStudyBanner";
import CaseStudyTopCard from "../../../Comman/CaseStudyBanner/CaseStudyTopCard";

import Savekar from "../../../../images/CaseStudiesImagesLogo/savekarBanner.png";
import SmartFiber from "../../../../images/CaseStudiesImagesLogo/SmartFiber_Logo.webp";
import Voeco from "../../../../images/CaseStudiesImagesLogo/VoecoLogo.png";
import Floomy from "../../../../images/CaseStudiesImagesLogo/FloomyLogo.svg";
import MeanShot from "../../../../images/CaseStudiesImagesLogo/MeanShot.svg";
import hubspot from "../../../../images/CaseStudiesImagesLogo/hubspot-icon.svg";
import Squire from "../../../../images/CaseStudiesImagesLogo/Squire.png";
import Bitlogo from "../../../../images/CaseStudiesImagesLogo/Bitlogo.png";
import idealeades from "../../../../images/casestudy/idealeades.webp";
import karibu from "../../../../images/CaseStudiesImagesLogo/karibu.png";
import Aarna from "../../../../images/CaseStudiesImagesLogo/Aarna.png";
import JainCommunity from "../../../../images/CaseStudiesImagesLogo/jainCommunity.png";

const cashStuduCards = [
  {
    img: hubspot,
    title: "Interact Hubspot",
    content:
      "Turn all your Calls into AI-Powered conversation intelligence tools",
    URL: "/casestudy/interact/",
    category: "WebDev",
  },
  {
    img: idealeades,
    title: "Idealeads",
    content: "Idealeads B2B Lead Generation Tools",
    URL: "/casestudy/idealeads/",
    category: "WebDev",
  },
  {
    img: MeanShot,
    title: "Meinshort",
    content: "Free Professional Card To Show Your Digital Presence",
    URL: "/casestudy/meinshort/",
    category: "WebDev",
  },
  {
    img: Squire,
    title: "Squire integration",
    content: "Integration between Squire and Ringcentral (BT Cloud).",
    URL: "/casestudy/communication/",
    category: "WebDev",
  },
  {
    img: Floomy,
    title: "Floomy",
    content:
      "Simplifying project management with intuitive collaboration tools.",
    URL: "/casestudy/FloomyWeb/",
    category: "WebDev",
  },
  {
    img: Bitlogo,
    title: "73BitWeb",
    content:
      "Transforming Ideas into Reality with Cutting-Edge Technology Solutions",
    URL: "/casestudy/Seven3BitWeb/",
    category: "WebDev",
  },
  // {
  //   // img: Voeco,
  //   // title: "Voeco EV",
  //   // content: "Ensuring Client Satisfaction through Collaborative Project Management for Voeco EV's Website",
  //   // URL: "/casestudy/VoecoEvWeb/",
  //   // category: "WebDev",
  // },
  {
    img: SmartFiber,
    title: "SmartFiberWeb",
    content: "Simplifying advanced fibre networks through GlobalConnect.",
    URL: "/casestudy/SmartFiberWeb/",
    category: "WebDev",
  },
  {
    img: Savekar,
    title: "SavekarWeb",
    content:
      "Your Offshore Accounting Partner in US, UK, Ireland, Canada & Australia",
    URL: "/casestudy/SavekarWeb/",
    category: "WebDev",
  },
  {
    img: karibu,
    title: "KaribuWeb",
    content:
      "Enhancing Mind and Body Well-Being through an Innovative Online Wellness Platform",
    URL: "/casestudy/KaribuWeb/",
    category: "WebDev",
  },
  {
    img: Aarna,
    title: "Aarna Systems",
    content:
      "Leading Innovators in Portable Bone Density Scanners and Osteoporosis Solutions",
    URL: "/casestudy/AarnasystemsWeb/",
    category: "WebDev",
  },
  {
    img: JainCommunity,
    title: "Jain Community App (S2S Connect)",
    content:
      "Connecting the Jain community through networking and job opportunities.",
    URL: "/casestudy/JainCommunity/",
    category: "Community/Technology",
  },
];

const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];

export default function WebDevCaseStudyPage() {
  return (
    <>
      <CaseStudyBanner
        subHeading="Case Studies"
        heading="Web Development"
        description="Get to know more about the Web Development."
      />

      <CaseStudyTopCard
        cashStuduCards={cashStuduCards}
        subHeading="A Transformative Case Study"
        heading="Web Development Case Studies"
        description="Analyses of web development projects, methodologies, and outcomes."
      />

      <ServiceIndustries
        industryData={industryData}
        subHeading="Industries Transformed By Cloud"
        heading="Industries"
        description="Our experts will work with you to understand and solve specific industry problems using SaaS."
      />
    </>
  );
}
