import BannerImage from "../../../../images/CaseStudiesImagesLogo/Deske.png";

const data = [
  {
    slugurl: "/DeskeTimeManager",
    data: [
      {
        Banner: [
          {
            sector: "Technology",
            headingtext: "Revolutionizing Time Management with Deske Time Manager Chrome Extension",
            headingpara:
              "Deske Time Manager is an upgraded Chrome extension designed to enhance task management and user productivity. It features UI improvements, seamless API integrations, and gamified elements for an engaging experience.",
            image: BannerImage,
          },
        ],
        heading: "Revolutionizing Time Management with Deske Time Manager Chrome Extension",
        casestudy: {
          Problem:
            "The current Desc Chrome extension requires refinements, bug fixes, and feature enhancements to better support user attention management. Usability and functional gaps hinder user satisfaction and engagement, limiting its full potential as a time management tool.",
          challenge: [
            {
              heading: "The Challenges:",
              challengeli: [
                { First: "User Authentication: Integrating secure and seamless Google authentication and GumRoad API for license key verification." },
                { First: "Task & Session Management: Optimizing task creation, loop management, and level progression functionalities." },
                { First: "UI/UX Consistency: Ensuring adherence to Figma designs while maintaining a responsive and user-friendly interface." },
                { First: "Bug Fixes and Testing: Identifying and resolving existing bugs to improve system stability." },
              ],
            },
            {
              heading: "The Solutions:",
              challengeli: [
                { First: "UI Enhancements: Implement updates for key screens such as Google Authentication, Task & Session Management, Break Mode, and Shop based on Figma designs." },
                { First: "Seamless API Integrations: Integrate GumRoad for license verification and Google Firebase & Firestore for backend functionalities." },
                { First: "Enhanced Functionality: Introduce features like task and loop management, level progression, customizable settings, and sound notifications." },
                { First: "Comprehensive Testing: Conduct rigorous testing and bug fixing to ensure a stable and reliable extension." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: "Improved User Engagement: Enhanced task and session management, personalized settings, and gamification elements will boost user satisfaction and productivity." },
                { First: "Seamless Authentication: Integration with Google and GumRoad APIs ensures smooth user login and license verification processes." },
                { First: "Increased Retention: The introduction of levels, rewards, and shop features will encourage continued use and engagement." },
                { First: "Enhanced Performance: Comprehensive testing and bug fixes will deliver a stable, reliable, and responsive user experience." },
              ],
            },
          ],
        },
        companyinfo: {
          logourl: "DeskeTimeManager",
          about: "About",
          aboutpara:
            "Deske Time Manager is an upgraded Chrome extension designed to enhance task management and user productivity. It features UI improvements, seamless API integrations, and gamified elements for an engaging experience.",
          companysector: [
            { first: "INDUSTRY:", secound: "Technology" },
            { first: "LOCATION:", secound: "Udaipur" },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
