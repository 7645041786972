import BannerImage from "../../../../images/CaseStudiesImagesLogo/EmailExtractor.png";

const data = [
  {
    slugurl: "/EmailExtractor", // Update the URL here
    data: [
      {
        Banner: [
          {
            sector: "Technology",
            headingtext: "Efficiently Extracting Emails from Web Sources",
            headingpara:
              "Our Email Extractor Extension streamlines email gathering with advanced functionality, supporting businesses, researchers, and marketers with accurate, efficient contact collection.",
            image: BannerImage,
          },
        ],
        heading: "Efficiently Extracting Emails from Web Sources with Advanced Functionality",
        casestudy: {
          Problem: "Challenges included scalability, data accuracy, and usability in email extraction.",
          challenge: [
            {
              heading: "The Challenges:",
              challengeli: [
                { First: "Scalability: Extracting emails from multiple pages efficiently." },
                { First: "Data Accuracy: Capturing all available emails without duplication." },
                { First: "Usability: Creating a user-friendly interface for non-technical users." },
              ],
            },
            {
              heading: "The Solutions:",
              challengeli: [
                { First: "Google Search Tab: Users can initiate searches from the extension, scraping emails from Google results." },
                { First: "Current Page Tab: Scans the active page for emails and displays results." },
                { First: "Multiple URL Tab: Allows users to paste multiple URLs, extracting emails from each in sequence." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: "Automated email collection across various web pages improves efficiency, flexibility, and productivity in contact collection." },
              ],
            },
          ],
        },
        companyinfo: {
          logourl: "emailextractor",
          
          about: "About",
          aboutpara:
            "The Email Extractor Extension is a robust tool for gathering emails quickly from diverse web sources, streamlining the process for marketers, researchers, and business professionals.",
          companysector: [
            // {
            //   first: "Company Name:",
            //   secound: "Your Company Name",
            // },
            {
              first: "INDUSTRY:",
              secound: "Technology",
            },
            {
              first: "LOCATION:",
              secound: "",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
