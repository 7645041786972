import React, { useState } from "react";
import CaseStudySection from "../../Comman/CaseStudy/CaseStudy";
import DriveMetrics from "../../Comman/Drivematrix/DriveMetrics";
import ScheduleCall from "../../Comman/ScheduleCall/ScheduleCall";
import Whyus from "../../Comman/Why Us/Whyus";
import Home from "./home/home";
import Ourtechstack from "../SubSection/ourtechstack/Ourtechstack";
import OurTestimonials from "../SubSection/ourtestimonials/OurTestimonials";
import Service from "./ServiceSection/services-section";
import FAQ from "../../Comman/FAQ/FAQ";
import { Helmet } from "react-helmet";

import { SuperSEO } from "react-super-seo";
import Companies from '../../Comman/Companies/Companies'
const Fdata = [
  {
    heading: "NLP ",
    subData: [
      {
        accordionHead: "How can sentiment analysis be used?",
        accordionPara:
          "Sentiment analysis is used in a number of areas. Brand management companies use it to help them understand what consumers think about their brand. Governments also use it to find out what people feel and think about the policies the government implements. Social media platforms also use it to understand their users and the context of interactions in relation to platform principles.",
        id: "1",

      },

      {
        accordionHead: "Will NLP bring value to my company?",
        accordionPara:
          "Big companies like Google, Facebook, Amazon, and Microsoft have long used NLP to improve their products and services. Many startups are also adopting NLP technology with great success. If you have a business that does customer service, data entry, word processing, or document creation, NLP can save you time and money.\nHowever, there is no guarantee that custom NLP software will achieve this expected value or that the total cost will not be too high to manage. This is why the detailed planning phase and iterative approach are so important in every AI-based project. Only then can you predict your costs, revenue, and break-even points in your growth. There are ways to reduce the risk of not taking advantage of solutions that use AI. LakeBrains' methodology is based on a deep understanding of the project's needs and the capabilities of current AI technologies, along with an iterative approach. Our project methodology focuses on creating value as early as possible and starts small with the development of the IA Proof of Concept.",
        id: "2",

      },
    ],
  },
  {
    heading: "Browser Extension",
    subData: [
      {
        accordionHead:
          " Why should you go with Helpful Insight for Chrome extension Development?",
        accordionPara:
          "Helpful Insight is a leading Chrome extension development company that specialises in creating simple and complex websites in response to social trends. We have a team of developers who are professionals in developing websites for different businesses. We hire developers who can come up with the best development strategy, helping to increase innovation and scalability.Chrome browser extension development services are also provided for enhanced operation.",
        id: "3",
      },
      {
        accordionHead:
          "How much does it cost to create a Chrome extension at Helpful Insight?",
        accordionPara:
          "Our goal is to deliver high-quality, fast and efficient websites at competitive prices entirely based on the services our clients require. We estimate application development costs based on business needs and provide the best services available. Please contact us with your specific requirements, and we will give you the best quote.",
        id: "4",
      },
      {
        accordionHead: "How long does it take for an Artificial Intelligence Development project to go live?",
        accordionPara: "For an AI project to go live, it can take anywhere from a few months to a year, depending on the scope and complexity of the AI ​​project. The time it takes to prepare data before a data science engineer builds an AI algorithm should not be underestimated.",
        id: "1",
      },
      {
        accordionHead: "How to apply AI in web development?",
        accordionPara: "If you want to build an AI-powered web application, you need to start by designing an architecture that will integrate different AI models. To make it 'future proof' we recommend not just focusing on the samples you are starting with. Don't just focus on temporary needs. Instead, try to identify all the places where there is potential to be powered by AI in the future, regardless of the data you currently have or your current business processes, needs, and priorities. As a result, you will be able to plan the entire architecture of this new system in a way that will allow the integration of other artificial intelligence models in the future.",
        id: "2",
      },
      {
        accordionHead: "What is a good NLP company",
        accordionPara: "A good NLP company is a trusted technology company that has established expertise in natural language processing and provides customized solutions based on your business needs.",
        id: "3",
      },
      {
        accordionHead: "I have a lot of data. Can Lakebrains help me clean and prepare it for NLP?",
        accordionPara: "We have worked with a wide range of data sources and formats. We can assist you with cleaning and preparing data for natural language processing. Not only can our team support you with data pre-processing or annotation, but we can also develop and implement a custom data collection strategy tailored to your specific needs.",
        id: "4",
      },
      {
        accordionHead: "How much data do I need for a natural language processing project?",
        accordionPara: "There is no single answer to this question. Depending on the type of work you are trying to do, its complexity, and the resources at your disposal, the amount of data required for a natural language processing project will vary. In general, however, you should have as much data as possible when working with deep learning projects, as more data will allow the algorithm to learn and improve its performance.\nOur data scientists are experienced in providing preliminary data quality assessments and preparing a dedicated data collection strategy for our clients.",
        id: "5",
      },
    ],
  },
];

const Homepage = () => {
  const [showPopUp, setShowPopUp] = useState(false);

  return (
    <>
      <SuperSEO
        title="NLP Service provider Company in India- Home"
        description="LakeBrains is an AI-powered software development company. Majorly provide service in NLP Service provider company, SaaS Development company in India"
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: "https://www.youtube.com/watch?v=wZ02FUjCYHw&t=3s&ab_channel=LakeBrainsTechnologies",
            summaryCardImageAlt: "video",
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
      <Home />
     
      <DriveMetrics />
      <CaseStudySection />
      <OurTestimonials />
      <Service />
      <Companies />
      <Ourtechstack />
      <Whyus />
      <ScheduleCall />
      <FAQ Fdata={Fdata} />
    </>
  );
};

export default Homepage;
