import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";
import "./header.css";
import Logo from "../../../images/logo/logo_lakebrains.webp";
import product_data from "../../routes/product_data";
import "rsuite/dist/rsuite.min.css";
import { Dropdown } from "rsuite";

const Header = () => {
  const [data, setData] = useState([]);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    setData(product_data);
  }, []);

  const onNavClick = () => {
    if (toggleMenu) {
      setToggleMenu(false);
    }
  };

  const onToggle = () => {
    setToggleMenu(!toggleMenu);
  };

  const renderHamburgerMenu = () => (
    <div className={`hamburger-menu ${toggleMenu ? 'open' : ''}`} onClick={onToggle}>
      <div className="bar-top"></div>
      <div className="bar-middle"></div>
      <div className="bar-bottom"></div>
    </div>
  );

  const renderNavItem = (to, label) => (
    <li className="nav-item">
      <Link onClick={onNavClick} className="nav-link" to={to}>
        {label}
      </Link>
    </li>
  );

  const renderNavDropdown = (title, items, titleLink) => (
    <Dropdown
      title={<Link to={titleLink} className="dropdown-title-link" onClick={onNavClick}>{title}</Link>}
      className="nav-link mb-3px"
      trigger="hover"
    >
      {items.map((item, index) => (
        <Dropdown.Item key={index}>
          <Link className="dropdown-item" to={item.to}>
            {item.label}
          </Link>
        </Dropdown.Item>
      ))}
    </Dropdown>
  );

  const services = [
    { to: "/saas-product-service-provider-company/", label: "SaaS Product" },
    { to: "/ai-ml-Development-service-provider-company/", label: "AI/ML Development" },
    { to: "/cloud-deployment-service-provider-company/", label: "Cloud Deployment" }
  ];

  const caseStudies = [
    { to: "/casestudy/ai-ml-development/", label: "AI-ML" },
    { to: "/casestudy/extension-addon-development/", label: "Extension / Addon" },
    { to: "/casestudy/web-development/", label: "Web Development" },
    { to: "/casestudy/web-automation/", label: "Web Automation" }
  ];

  const solutions = [
    { to: "/api/empathy/", label: "Empathy" },
    { to: "/api/ChatGptQandA/", label: "ChatGPT Q & A" }
  ];

  return (
    <div className="header-wrap">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <Link onClick={onNavClick} className="navbar-brand" to={"/"}>
            <img src={Logo} alt="Lakebrains Technologies" />
          </Link>
          <button className="navbar-toggler" type="button" onClick={onToggle}>
            {renderHamburgerMenu()}
          </button>

          <div className={`collapse navbar-collapse ${toggleMenu ? 'show' : ''}`} id="navbarColor01">
            <ul className="navbar-nav  ">
              {renderNavItem("/about-us/", "About Us")}
             <div className="dropdown-1"> {renderNavDropdown("Services", services)}</div>
          <div className="dropdown-1">    {renderNavDropdown("Case Studies", caseStudies, "/casestudy")}</div>
              {renderNavItem("https://testimonial.to/lakebrains-technologies/all", "Testimonial")}
             {/* {renderNavItem("/testimonial", "Testimonial")}  */}
              {/* {renderNavDropdown("Solutions", solutions)} */}
            </ul>
            <div className="contact_us_btn_header">
              <PopupButton
                className="btn letstalk-hidden header-blogs-btn"
                text="Let's talk"
                url="https://calendly.com/khushbu-jpao/opportunity"
                rootElement={document.getElementById("root")}
              />
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
