// import React from "react";
// import { PopupButton } from "react-calendly";
// import KamleshPhoto from "../../../images/AboutUs/KamleshPhoto.webp";
// import "./ScheduleCall.css";

// const ScheduleCall = () => {
//   return (
//     <div className="example-classname">
//       <section className="SheduleCall-Wrapper ">
//         <div className="container ">
//           <div className="homepage-heading ">
//             <h4>SCHEDULE A CALL WITH US</h4>
//             <h2 className="text-capitalize">We Are Ready To Get Started?</h2>
//             <p>
//               I would love to answer your questions or learn more about your
//               project. Please let us know how we can help you Schedule a meeting
//               now.
//             </p>
//           </div>
//           <hr />
//           <div className="SheduleCall-Main-items-wrapper">
//             <div className="text-center">
//               <div className="SheduleCall-a-Photo">
//                 <img src={KamleshPhoto} alt="Kamlesh Meghwal" />
//               </div>
//               <p className="text-1 font-w-600 font-s-18 mb-2">
//                 KAMLESH MEGHWAL
//               </p>
//               <p className="text-secondary ">Business Development Manager</p>
//             </div>
//             <div className="row mt-4 px-5 justify-content-around">
//               <div className=" col-lg-4 col-md-6 col-sm-12 text-center mt-4 ">
//                 <button className=" mb-2 SheduleCall-item-no">1</button>
//                 <p className="text-1 font-w-600 mb-2">
//                   Talk to One of Our Industry Experts
//                 </p>
//                 <p className="text-secondary font-s-14">
//                   An expert of our team will work with you to understand your
//                   goals
//                 </p>
//               </div>
//               <div className=" col-lg-4 col-md-6 col-sm-12 text-center mt-4 ">
//                 <button className=" mb-2 SheduleCall-item-no">2</button>
//                 <p className="text-1 font-w-600 mb-2">
//                   Work With hand-selected Talent
//                 </p>
//                 <p className="text-secondary font-s-14">
//                   Within days, we'll introduce you to the right talent for your
//                   project. The average time to match is less than 24 hours.
//                 </p>
//               </div>
//               <div className=" col-lg-4 col-md-6 col-sm-12 text-center mt-4 ">
//                 <button className=" mb-2 SheduleCall-item-no">3</button>
//                 <p className="text-1 font-w-600 mb-2">
//                   The Right Fit, Guaranteed
//                 </p>
//                 <p className="text-secondary font-s-14">
//                   Work with your new team member on a trial basis (pay only if
//                   satisfied), ensuring you hire the right people for the job.
//                 </p>
//               </div>
//             </div>
//             <div className="text-center my-4 pb-5">
//               <PopupButton
//                 className="btn schedule-meeting-btn m-auto"
//                 href="https://calendly.com/khushbu-jpao/30min?back=1&month=2024-05"
//                 rootElement={document.getElementById("root")}
//                 text="Schedule Meeting"
//               />
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default ScheduleCall;


import React from "react";
import { PopupButton } from "react-calendly";
import KamleshPhoto from "../../../images/AboutUs/KamleshPhoto.webp";
import GulamPhoto from "../../../images/AboutUs/GulamPhoto.png";
import "./ScheduleCall.css";

const ScheduleCall = () => {
  return (
    <div className="example-classname">
      <section className="SheduleCall-Wrapper ">
        <div className="container ">
          <div className="homepage-heading ">
            <h4>SCHEDULE A CALL WITH US</h4>
            <h2 className="text-capitalize">We Are Ready To Get Started?</h2>
            <p>
              I would love to answer your questions or learn more about your
              project. Please let us know how we can help you Schedule a meeting
              now.
            </p>
          </div>
          <hr />
          <div className="SheduleCall-Main-items-wrapper">
            <div className="text-center">
              <div className="SheduleCall-a-Photo">
                <img src={KamleshPhoto} alt="Kamlesh Meghwal" />
              </div>
              <p className="text-1 font-w-600 font-s-18 mb-2">
                KAMLESH MEGHWAL
              </p>
              <p className="text-secondary ">Business Development Manager</p>
            </div>
            <div className="row mt-4 px-5 justify-content-around">
              <div className=" col-lg-4 col-md-6 col-sm-12 text-center mt-4 ">
                <button className=" mb-2 SheduleCall-item-no">1</button>
                <p className="text-1 font-w-600 mb-2">
                  Talk to One of Our Industry Experts
                </p>
                <p className="text-secondary font-s-14">
                  An expert of our team will work with you to understand your
                  goals
                </p>
              </div>
              <div className=" col-lg-4 col-md-6 col-sm-12 text-center mt-4 ">
                <button className=" mb-2 SheduleCall-item-no">2</button>
                <p className="text-1 font-w-600 mb-2">
                  Work With hand-selected Talent
                </p>
                <p className="text-secondary font-s-14">
                  Within days, we'll introduce you to the right talent for your
                  project. The average time to match is less than 24 hours.
                </p>
              </div>
              <div className=" col-lg-4 col-md-6 col-sm-12 text-center mt-4 ">
                <button className=" mb-2 SheduleCall-item-no">3</button>
                <p className="text-1 font-w-600 mb-2">
                  The Right Fit, Guaranteed
                </p>
                <p className="text-secondary font-s-14">
                  Work with your new team member on a trial basis (pay only if
                  satisfied), ensuring you hire the right people for the job.
                </p>
              </div>
            </div>
            <div className="text-center my-4 pb-5">
              <PopupButton
                className="btn schedule-meeting-btn m-auto"
                url="https://calendly.com/khushbu-jpao/opportunity"
                rootElement={document.getElementById("root")}
                text="Schedule Meeting"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ScheduleCall;

