import BannerImage from "../../../../images/CaseStudiesImagesLogo/Respark.png";

const data = [
  {
    slugurl: "/ResparkAi",
    data: [
      {
        Banner: [
          {
            sector: "Technology",
            headingtext: "Revolutionizing YouTube Content Interaction and Social Media Engagement",
            headingpara:
              "Respark.ai enhances YouTube content consumption by automating transcription, summarization, and social media post creation. It empowers users with personalized insights and seamless engagement through AI-driven solutions.",
            image: BannerImage,
          },
        ],
        heading:" Revolutionizing YouTube Content Interaction and Social Media Engagement ",
        casestudy: {
          Problem:
            "Users face challenges in efficiently managing and interacting with YouTube video content, such as accessing transcripts, receiving timely summaries, and creating personalized social media posts. These limitations hinder user engagement and content consumption efficiency.",
          challenge: [
            {
              heading: "The Challenges:",
              challengeli: [
                { First: "Efficient Transcription & Summarization: Capturing and processing lengthy video transcripts to deliver concise, meaningful insights." },
                { First: "Social Media Post Creation: Automating platform-specific post generation without compromising quality." },
                { First: "User Personalization: Tailoring recommendations and content summaries based on user preferences." },
                { First: "Seamless Integration: Ensuring smooth interactions between the extension, backend, and web app." },
              ],
            },
            {
              heading: "The Solutions:",
              challengeli: [
                { First: "Extension Features: Capture YouTube transcripts, monitor subscribed channels, send notifications for new summaries, and support Google authentication." },
                { First: "Backend Features: Process video transcripts, generate time-stamped insights, and create AI/ML-powered social media posts." },
                { First: "Web App Features: Provide summarization public web pages and offer UI/UX features for better user experience." },
                { First: "Integration with Canva: Enable users to customize social media posts, streamlining the content creation workflow." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: "Enhanced Content Accessibility: Faster access to meaningful video insights through automated transcription and summarization." },
                { First: "Boosted Social Media Engagement: AI-driven post generation for platforms like LinkedIn, Facebook, Twitter, and Instagram." },
                { First: "Improved User Productivity: Personalized summaries and seamless notifications keep users informed and engaged." },
                { First: "Revenue Opportunities: Monetization through premium features like extended storage and advanced analytics." },
              ],
            },
          ],
        },
        companyinfo: {
          logourl: "aichatbox",
          about: "About",
          aboutpara:
            "Respark.ai enhances YouTube content consumption by automating transcription, summarization, and social media post creation. It empowers users with personalized insights and seamless engagement through AI-driven solutions.",
          companysector: [
            { first: "INDUSTRY:", secound: "Technology" },
            { first: "LOCATION:", secound: "Udaipur" },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
