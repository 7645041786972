import React, { useEffect, useState } from "react";
import "./OutingPage.css";
import pic1 from "../../../../images/Outing/pic1.jpg";
import pic2 from "../../../../images/Outing/pic2.jpg";
import pic3 from "../../../../images/Outing/pic3.jpeg";
import pic4 from "../../../../images/Outing/pic4.jpg";
import pic5 from "../../../../images/Outing/pic5.jpg";
import pic6 from "../../../../images/Outing/pic6.jpeg";

import pic7 from "../../../../images/Outing/LB_Workforce_ParticipationCopy(4).jpg";
import pic8 from "../../../../images/Outing/LB_Workforce_Participation (10).jpeg";
import pic9 from "../../../../images/Outing/LB_Workforce_Participation (9).jpeg";

import pic10 from "../../../../images/Outing/LB_Workforce_Participation (1).jpeg";
import pic11 from "../../../../images/Outing/LB_Workforce_Participation (1).jpg";
import pic12 from "../../../../images/Outing/LB_Workforce_Participation (2).jpeg";
import pic13 from "../../../../images/Outing/LB_Workforce_Participation (2).jpg";
import pic14 from "../../../../images/Outing/LB_Workforce_Participation (3).jpeg";
import pic15 from "../../../../images/Outing/LB_Workforce_Participation (3).jpg";
import pic16 from "../../../../images/Outing/LB_Workforce_Participation (4).jpeg";
import pic17 from "../../../../images/Outing/LB_Workforce_Participation (5).jpeg";
import pic18 from "../../../../images/Outing/LB_Workforce_Participation (6).jpeg";
import pic19 from "../../../../images/Outing/LB_Workforce_Participation (7).jpeg";
import pic20 from "../../../../images/Outing/LB_Workforce_Participation (8).jpeg";
import pic21 from "../../../../images/Outing/LB_Workforce_Participation (11).jpeg";
import pic22 from "../../../../images/Outing/LB_Workforce_Participation (12).jpeg";
import pic23 from "../../../../images/Outing/LB_Workforce_Participation (13).jpeg";
import pic24 from "../../../../images/Outing/LB_Workforce_Participation (14).jpeg";
import pic25 from "../../../../images/Outing/LB_Workforce_Participation (15).jpeg";
import pic26 from "../../../../images/Outing/LB_Workforce_Participation (16).jpeg";
import pic27 from "../../../../images/Outing/LB_Workforce_Participation (17).jpeg";
import pic28 from "../../../../images/Outing/LB_Workforce_Participation (18).jpeg";
import pic29 from "../../../../images/Outing/LB_Workforce_Participation (19).jpeg";
import pic30 from "../../../../images/Outing/LB_Workforce_Participation (20).jpeg";
import pic31 from "../../../../images/Outing/LB_Workforce_Participation (21).jpeg";
import pic32 from "../../../../images/Outing/LB_Workforce_Participation (22).jpeg";
import pic33 from "../../../../images/Outing/LB_Workforce_Participation (23).jpeg";
import pic34 from "../../../../images/Outing/LB_Workforce_Participation (24).jpeg";



import prev from "../../../../images/Outing/prev.png";
import next from "../../../../images/Outing/next.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function OutingPage() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images1 = [pic1, pic2, pic3];
  const [currentSlide, setCurrentSlide] = useState(0);

  const images2 = [
    // { src: pic7, alt: "Slide 1" },
    // { src: pic8, alt: "Slide 2" },
    // { src: pic9, alt: "Slide 3" },
    // { src: pic10, alt: "Slide 4" },
    // { src: pic11, alt: "Slide 5" },
    // { src: pic12, alt: "Slide 6" },
    // { src: pic13, alt: "Slide 1" },
    // { src: pic14, alt: "Slide 2" },
    // { src: pic15, alt: "Slide 3" },
    // { src: pic16, alt: "Slide 4" },
    // { src: pic17, alt: "Slide 5" },
    // { src: pic18, alt: "Slide 6" },
    // { src: pic19, alt: "Slide 1" },
    // { src: pic20, alt: "Slide 2" },
    // { src: pic21, alt: "Slide 3" },
    // { src: pic22, alt: "Slide 4" },
    // { src: pic23, alt: "Slide 5" },
    // { src: pic24, alt: "Slide 6" },
    // { src: pic25, alt: "Slide 1" },
    // { src: pic26, alt: "Slide 2" },
    // { src: pic27, alt: "Slide 3" },
    // { src: pic28, alt: "Slide 4" },
    // { src: pic29, alt: "Slide 5" },
    // { src: pic30, alt: "Slide 6" },
    // { src: pic31, alt: "Slide 1" },
    // { src: pic32, alt: "Slide 2" },
    // { src: pic33, alt: "Slide 3" },
    // { src: pic34, alt: "Slide 4" },

    { src: pic1, alt: "Slide 1" },
    { src: pic2, alt: "Slide 2" },
    { src: pic3, alt: "Slide 3" },
    { src: pic4, alt: "Slide 4" },
    { src: pic5, alt: "Slide 5" },
    { src: pic6, alt: "Slide 6" },
  ];
 

  const [sliderRef, setSliderRef] = useState(null);
  useEffect(() => {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    if (sliderRef) {
      sliderRef.slickGoTo(0); // Reset carousel to the first slide when component mounts
    }

    return () => {
      if (sliderRef) {
        sliderRef.slickGoTo(0); // Reset carousel to the first slide when component unmounts
      }
    };
  }, [sliderRef]);

  const handleNext2 = () => {
    setCurrentSlide((prevIndex) =>
      prevIndex === images2.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev2 = () => {
    setCurrentSlide((prevIndex) =>
      prevIndex === 0 ? images2.length - 1 : prevIndex - 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images1.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images1.length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <>
      <div className="outing-div-1">
        <div className="container">
          <div className="row p-3 ">
            <div className="col-md-6 mt-4">
              <h2 className="outing-title ">Office Outing !</h2>
              <p className="mt-2">
                LakeBrains Technology outing is a remarkable experience where
                the boundaries between work and leisure are beautifully blurred,
                allowing us to connect and thrive as a close-knit family. This
                annual event brings together our talented team in a picturesque
                setting, providing an ideal opportunity to unwind, rejuvenate,
                and strengthen the bonds that go beyond professional
                relationships. As we embark on various adventure activities and
                team-building exercises, we celebrate our collective
                achievements, share laughter, and create memories that foster a
                sense of togetherness.
              </p>
              <p>
                Whether it's tackling obstacles or enjoying a friendly game of
                volleyball, we come together, support each other, and cherish
                the feeling of being part of something larger than ourselves.
                Ultimately, the LakeBrains Technology outing not only promotes a
                harmonious work environment but also reinforces the notion that
                we are not just colleagues but a family united by a common
                purpose.
              </p>
            
            </div>
            <div className="col-md-6 mt-4">
              <div className=" mt-3 mb-3">
                <div className="">
                  <img className="outingImg" src={pic7} alt="OutingImage" />
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <img className="outingImg" src={pic8} alt="OutingImage" />
                  </div>
                  <div className="col-md-6">
                    <img className="outingImg" src={pic9} alt="OutingImage" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="outing-div-2">
        <div className="container">
          <div className="row mt-5 p-3">
            <div className="col-md-5">
              <h3 className="outing-title">Recents</h3>
              <p className="pt-3 latest-place">
                See the latest places we have been to.
              </p>
              <div className="d-flex justify-content-center mb-4 mt-4">
                <button className="arrow-img" onClick={handlePrev}>
                  {" "}
                  <img className=" pr-nt" src={prev} alt="" />
                </button>
                <button className="arrow-img" onClick={handleNext}>
                  {" "}
                  <img className=" pr-nt" src={next} alt="" />
                </button>
              </div>
            </div>
            <div className="col-md-7">
              <div className="container">
                <img
                  className="outingImg"
                  src={images1[currentIndex]}
                  alt={` ${currentIndex + 1}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="outing-div-3 mt-5">
        <div className="container">
          <h2 className="text-center outing-title">Some of our Best Moments</h2>
          <div className="container">
            <div className="carousel-wrapper">
              <div className="row mt-3">
                <div className="col-md-1 col-sm-1 m-auto">
                  <div className="arrow-wrapper">
                    <button
                      className="custom-arrow custom-prev"
                      onClick={handlePrev2}
                    >
                      <img className="arrow-img pr-nt-2 " src={prev} alt="" />
                    </button>
                  </div>
                </div>
                <div className="col-md-10 col-sm-10">
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    autoPlay={true}
                    interval={2000}
                    infiniteLoop={true}
                    swipeable={true}
                    emulateTouch={true}
                    selectedItem={currentSlide}
                    onChange={(index) => setCurrentSlide(index)}
                  >
                    {images2.map((image, index) => (
                      <div key={index}>
                        <div className="slide-row">
                          <div className="row mt-2">
                            <img
                              className="col-md-7 col-sm-7 outingImg"
                              src={image.src}
                              alt={image.alt}
                            />
                            <img
                              className="col-md-5 col-sm-5 outingImg"
                              src={images2[(index + 1) % images2.length].src}
                              alt={images2[(index + 1) % images2.length].alt}
                            />
                          </div>
                        </div>
                        <div className="slide-row">
                          <div className="row mt-2">
                            <img
                              className="col-md-6 col-sm-6 outingImg"
                              src={images2[(index + 2) % images2.length].src}
                              alt={images2[(index + 2) % images2.length].alt}
                            />
                            <img
                              className="col-md-6 col-sm-6 outingImg"
                              src={images2[(index + 3) % images2.length].src}
                              alt={images2[(index + 3) % images2.length].alt}
                            />
                          </div>
                        </div>
                        <div className="slide-row">
                          <div className="row mt-2 mb-3">
                            <img
                              className="col-md-5 col-sm-5 outingImg"
                              src={images2[(index + 4) % images2.length].src}
                              alt={images2[(index + 4) % images2.length].alt}
                            />
                            <img
                              className="col-md-7 col-sm-7 outingImg"
                              src={images2[(index + 5) % images2.length].src}
                              alt={images2[(index + 5) % images2.length].alt}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                  <div className="pagination">
                    <button className="pagination-btn active">
                      <div style={{ color: "white" }}>
                        {" "}
                        {currentSlide + 1}/{images2.length}
                      </div>
                    </button>
                  </div>
                </div>
                <div className="col-md-1 col-sm-1 m-auto">
                  <div className="arrow-wrapper">
                    <button
                      className="custom-arrow custom-next"
                      onClick={handleNext2}
                    >
                      <img className="arrow-img pr-nt-2" src={next} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OutingPage;
