import Bannnerimage from "../../../../images/casestudy/ReaExtension.png";
import Logo from "../../../../images/logo/logo_lakebrains.webp";


const data = [
  {
    slugurl: "/ReaExtension",
    data: [
      {
        Banner: [
          {
            sector: "Extension/ Technology",
            headingtext: "Rea Chrome Extension",
            headingpara:
              "Rea is a valuable chrome extension tool To Remind saved articles by desktop notifications",
            image: Bannnerimage,
          },
        ],
        heading: " Save bookmarks and set notifications alarm to any bookmarks",
        casestudy: {
          Problem: `" A Chrome extension, designed to simplify your online experience. With ease, save websites as bookmarks, group them for easy access, and set timely reminders. Effortlessly streamline your browsing, ensuring you never overlook crucial content. Boost productivity and efficiency with our user-friendly, intuitive tool.
          "`,
          challenge: [
            {
              heading: "How to use it:- ",
              challengeli: [
                { First: "Download Rea Chrome Extension" },
                {
                  First: "Click on the extension icon",
                },
                {
                  First:
                    "Click on top left button to add bookmark any article/website.",
                },
                {
                  First:
                    "Add tags by tags button on top left beside bookmark button and input tag name.",
                },
                {
                  First:
                    "Click on 3-dots on any saved bookmark to add a tag to it.",
                },
                {
                  First:
                    "To add alarm click on the alarm button on any of the saved bookmark, select your time to reminder and save it.",
                },
              ],
            },
            {
              heading: "The Challenges:- ",
              challengeli: [
                {
                  First:
                    "Integration of Twitter Authentication in chrome extension was done on the extension side.",
                },
                {
                  First:
                    "Integrating Stripe payment gateway was challenging as chrome extensions doesn't allow external scripts to run so we redirected to stripe page directly.",
                },
                {
                  First:
                    "To show notifications on time we used chrome's alarm API to show notifications on time.",
                },
              ],
            },
            {
              heading: "The Approaches:-",
              challengeli: [
                {
                  First:
                    "To integrate twitter auth we called 2 apis at extension to backend and in backend we call 2 apis to get access token for verification.",
                },
                {
                  First:
                    "To use stripe we get the checkout page link from backend and we redirect them to stripe page and if user successfully completes the payment they are redirected to success url page where we get session ID to save user successful payment.",
                },
                {
                  First:
                    "To show browser notifications we used chrome alarm api to set the time for trigger notification and when the time completes, it then creates a new notification.",
                },
              ],
            },
            {
              heading: "Impact",
              challengeli: [
                {
                  First: ` 
                  The REA extension promises to revolutionize browsing habits, offering users a streamlined approach to managing web content. By providing effortless bookmarking, intuitive organization, and timely reminders, it empowers users to efficiently curate and revisit valuable online resources. This not only enhances productivity by eliminating the frustration of lost or forgotten content but also fosters a more organized and informed browsing experience. With the ability to tailor groups to personal preferences, users can effectively manage diverse interests and professional pursuits, ultimately optimizing their online journey and maximizing the value extracted from web exploration.
                  `,
                },
              ],
            },
          ],
        },

        companyinfo: {
          logourl: "",
          about: "About ",
          aboutpara:
            "Rea is a valuable chrome extension tool To Remind saved articles by desktop notifications.",
          companysector: [
            {
              first: "Client Name:",
              secound: "Karen Redona",
            },
            {
              first: "INDUSTRY:",
              secound: "Extension/ Technology",
            },
          ],
        },
        customer: [
      
        ],
      },
    ],
  },
];

export default data;
